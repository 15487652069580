let envs = {
  production: {
    apiUrl: process.env.REACT_APP_API_URL,
    perfectUrl: 'https://www.perfect-cef.fr',
    GTM: {
      code: process.env.REACT_APP_GTM_ID,
    },
    debug: false,
    payment: {
      salt1: process.env.PAYMENT_SALT1,
      salt2: process.env.PAYMENT_SALT2,
      url: process.env.PAYMENT_URL,
    },
    IdleUserTimeout: 180000,
    DefaultEventsTracking: ['mousemove', 'keydown', 'wheel', 'DOMMouseScroll'],
    limitConnexionAmount: 10,
    TimeToSendData: 600,
    TimeToSendDataActivity: 3000,
    inactivityTime: 1000 * 3600,
    expirationDateSchoolReport: 'June 12, 2020 17:15:00',
    homework: {
      acceptedFiles: [
        'pdf',
        'doc',
        'docx',
        'png',
        'rtf',
        'odt',
        'jpg',
        'jpeg',
        'avi',
        'mp3',
        'mp4',
        'mov',
      ],
      sendingErrorEmail: 'professeur@cenef.fr',
      sentMessageDuration: 1000 * 15, //15m
    },
    examinations: {
      acceptedFiles: ['pdf', 'doc', 'docx', 'png', 'rtf', 'odt', 'jpg'],
      takingYearsRange: 4,
      cenefPhone: '+33 (0)3 66 06 10 30',
    },
    miscellaneous: {
      cenefHours: 'lundi au jeudi de 8h30 à 19h et le vendredi de 8h30 à 18h00',
      cenefPhone: '+33 (0)3 66 06 02 01',
    },
    chat: {
      acceptedFiles: ['pdf', 'jpg', 'jpeg', 'png', 'xls', 'csv', 'doc', 'txt'],
      sizeMaxAttachment: 20000000,
      sizeMaxAttachmentString: '20 Mo'
    }
  },
  staging: {
    apiUrl: process.env.REACT_APP_API_URL,
    GTM: {
      code: process.env.REACT_APP_GTM_ID,
    },
    IdleUserTimeout: 180000,
    DefaultEventsTracking: ['mousemove', 'keydown', 'wheel', 'DOMMouseScroll'],
    debug: true,
    payment: {
      salt1: process.env.PAYMENT_SALT1,
      salt2: process.env.PAYMENT_SALT2,
      url: process.env.PAYMENT_URL,
    },
    limitConnexionAmount: 10,
    TimeToSendData: 600,
    TimeToSendDataActivity: 3000,
    expirationDateSchoolReport: 'June 12, 2020 17:15:00',
    inactivityTime: 1000 * 3600 * 3,
    homework: {
      acceptedFiles: [
        'pdf',
        'doc',
        'docx',
        'png',
        'rtf',
        'odt',
        'jpg',
        'jpeg',
        'avi',
        'mp3',
        'mp4',
        'mov',
      ],
      sendingErrorEmail: 'cestmoileprof@centre-europeen-formation.fr',
      sentMessageDuration: 1000 * 15, //15m
    },
    examinations: {
      acceptedFiles: ['pdf', 'doc', 'docx', 'png', 'rtf', 'odt', 'jpg'],
      takingYearsRange: 4,
      cenefPhone: '+33 (0)3 66 06 10 30',
    },
    miscellaneous: {
      cenefHours: 'lundi au jeudi de 8h30 à 19h et le vendredi de 8h30 à 18h00',
      cenefPhone: '+33 (0)3 66 06 02 01',
    },
    chat: {
      acceptedFiles: ['pdf', 'jpg', 'jpeg', 'png', 'xls', 'csv', 'doc', 'txt'],
      sizeMaxAttachment: 15000000,
      sizeMaxAttachmentString: '15 Mo'
    },
    rendezVousType: {
      phone: {title: 'Téléphone', id: 1},
      floating: {title: 'Floating', id: 2},
      visio: {title: 'Visioconférence', id: 3},
      booking: {title: 'Booking', id: 4}
    }
  },
  development: {
    apiUrl: process.env.REACT_APP_API_URL,
    perfectUrl: 'https://recette.perfect-cef.fr',
    GTM: {
      code: process.env.REACT_APP_GTM_ID
    },
    debug: true,
    limitConnexionAmount: 10,
    expirationDateSchoolReport: 'June 12, 2020 17:15:00',
    TimeToSendData: 600,
    TimeToSendDataActivity: 3000,
    payment: {
      salt1: process.env.PAYMENT_SALT1,
      salt2: process.env.PAYMENT_SALT2,
      url: process.env.PAYMENT_URL,
    },
    inactivityTime: 1000 * 60 * 30, //30 minutes
    IdleUserTimeout: 180000,
    DefaultEventsTracking: ['mousemove', 'keydown', 'wheel', 'DOMMouseScroll'],
    homework: {
      acceptedFiles: [
        'pdf',
        'doc',
        'docx',
        'png',
        'rtf',
        'odt',
        'jpg',
        'jpeg',
        'avi',
        'mp3',
        'mp4',
        'mov',
      ],
      sendingErrorEmail: 'cestmoileprof@centre-europeen-formation.fr',
      sentMessageDuration: 1000 * 15, //15m
    },
    examinations: {
      acceptedFiles: ['pdf', 'doc', 'docx', 'png', 'rtf', 'odt', 'jpg'],
      takingYearsRange: 4,
      cenefPhone: '+33 (0)3 66 06 10 30',
    },
    miscellaneous: {
      cenefHours: 'lundi au jeudi de 8h30 à 19h et le vendredi de 8h30 à 18h00',
      cenefPhone: '+33 (0)3 66 06 02 01',
    },
    chat: {
      acceptedFiles: ['pdf', 'jpg', 'jpeg', 'png', 'xls', 'csv', 'doc', 'txt'],
      sizeMaxAttachment: 15000000,
      sizeMaxAttachmentString: '15 Mo'
    },
    rendezVousType: {
      phone: {title: 'Téléphone', id: 1},
      floating: {title: 'Floating', id: 2},
      visio: {title: 'Visioconférence', id: 3},
      booking: {title: 'Booking', id: 4}
    }
  },
};

export default envs[process.env.REACT_APP_HOST_ENV || process.env.NODE_ENV];
