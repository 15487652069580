import PropTypes from 'prop-types';
import env from '../env.js';

let imgUrl = (opts) => {

  return [
    env.apiUrl,
    opts.fileName ? `picture/${opts.fileName}` : 'picture/none',
    opts.width && `${opts.width}`,
    opts.height && `${opts.height}`
  ]
    .filter((a) => a)
    .join('/');
};
imgUrl.propType = {
  fileName: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  q: PropTypes.number,
};

export default imgUrl;
