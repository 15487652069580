import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.css';


const Popin = ({ children, forceOpen, onRef, width, doClose, bgColor, disableClose, onOpen, onClose = null, height = null }) => {

  let isOpen = forceOpen || false;
  let $popin = undefined;

  const close = () => {
    if (!disableClose && $popin) {
      isOpen = false;
      $popin.classList.remove('show');
      document.body.style.overflow = 'unset';
      onClose && onClose();
    }
  };

  const forceClose = () => {
    if ($popin) {
      isOpen = false;
      $popin.classList.remove('show');
      document.body.style.overflow = 'unset';
    }
  };

  const open = () => {
    if ($popin) {
      isOpen = true;
      $popin.classList.add('show', 'loaded');
      document.body.style.overflow = 'hidden';
      onOpen && onOpen();
    }
  };

  const clickHandler = e => {
    if (
      (e.target.classList.contains('closable') && e.target.classList.contains('popin')) ||
      e.target.classList.contains('close')
    ) {
      close();
    }
  };

  const onPopinRef = $el => {
    $popin = $el;
    onRef && onRef(open);
    doClose && doClose(forceClose);
    $popin && $popin.addEventListener('click', clickHandler);
  };

  const removeHandler = () => {
    $popin && $popin.removeEventListener('click', clickHandler);
  };

  const getIsOpen = () => isOpen;

  const style = {
    ...(width ? { maxWidth: `${width}px` } : {}),
    ...(height ? { height: `${height}` } : {})
  };

  const classes = ['popin', `bg-${bgColor || 'bg-primary'}`, getIsOpen() && 'show']
    .filter(c => c)
    .join(' ');

  useEffect(() => {
    document.body.style.overflow = getIsOpen() ? 'hidden' : 'unset';
  }, [getIsOpen]);

  useEffect(() => {
    return () => {
      removeHandler();
    };
  }, []);

  return (
    <div className={classNames(classes, { closable: !disableClose })} ref={onPopinRef}>
      <div className="popin__inner" style={style}>
        {children}
      </div>
      {!disableClose && (
        <span className="close icon pg-0_25 round">
          <svg className="w-4 h-4">
            <use xlinkHref="#icon-close" />
          </svg>
        </span>
      )}
    </div>
  );
};

Popin.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.string,
  style: PropTypes.object,
  disableClose: PropTypes.bool,
  onPopinRef: PropTypes.func,
  forceOpen: PropTypes.any,
  onRef: PropTypes.any,
  width: PropTypes.any,
  doClose: PropTypes.any,
  bgColor: PropTypes.any,
  onOpen: PropTypes.any,
};

export default Popin;
