import Api, { params } from '../modules/Api.js';

import { IS_LOADING, ISNT_LOADING } from './isLoading';
import { SET_ERROR, RESET_ERROR } from './error';
import store from '../store';

export const GET_QUESTION_REQUESTED = 'questionsGroups/GET_QUESTION_REQUESTED';
export const POST_QUESTION_REQUESTED =
  'questionsGroups/POST_QUESTION_REQUESTED';
export const CHANGE_ANSWER_REQUESTED =
  'questionsGroups/CHANGE_ANSWER_REQUESTED';
export const GET_SCORING_REQUESTED = 'questionsGroups/GET_SCORING_REQUESTED';
export const RESET_QUESTIONS_REQUESTED =
  'questionsGroups/RESET_QUESTIONS_REQUESTED';
export const RESET_REVIEWING_REQUESTED =
  'questionsGroups/RESET_REVIEWING_REQUESTED';
export const RESET_HOMEWORK_REQUESTED =
  'questionsGroups/RESET_HOMEWORK_REQUESTED';
export const GET_HOMEWORK_PREVNEXT_QUESTION_REQUESTED =
  'questionsGroups/GET_HOMEWORK_PREVNEXT_QUESTION_REQUESTED';
export const GET_CHALLENGE_QUESTION_REQUESTED =
  'questionsGroup/GET_CHALLENGE_QUESTION_REQUESTED';
export const GET_CHALLENGE_SCORING_REQUESTED =
  'questionsGroup/GET_CHALLENGE_SCORING_REQUESTED';
export const POST_CHALLENGE_QUESTION_REQUESTED =
  'questionsGroup/POST_CHALLENGE_QUESTION_REQUESTED';
export const RESET_CHALLENGE_REQUESTED =
  'questionsGroup/RESET_CHALLENGE_REQUESTED';
export const GET_HOMEWORK_CORRECTION_REQUESTED =
  'homeworks/GET_HOMEWORK_CORRECTION_REQUESTED';
export const GET_HOMEWORK_PREVNEXT_CORRECTION_REQUESTED =
  'questionsGroups/GET_HOMEWORK_PREVNEXT_CORRECTION_REQUESTED';

export const GET_HOMEWORK_FREE_QUESTION_SUCCESSFUL =
  'questionsGroups/GET_HOMEWORK_FREE_QUESTION_SUCCESSFUL';
export const GET_HOMEWORK_WITHOUT_QUESTION_SUCCESSFUL =
  'questionsGroups/GET_HOMEWORK_WITHOUT_QUESTION_SUCCESSFUL';
export const GET_QUESTION_SUCCESSFUL =
  'questionsGroups/GET_QUESTION_SUCCESSFUL';
export const GET_QUESTION_FAILED = 'questionsGroups/GET_QUESTION_FAILED';
export const SEND_FILE_REQUESTED = 'questionsGroups/SEND_FILE_REQUESTED';
export const SEND_FILE_SUCCESS = 'questionsGroups/SEND_FILE_SUCCESS';
export const SEND_FILE_FAILED = 'questionsGroups/SEND_FILE_FAILED';

export const POST_QUESTION_SUCCESSFUL =
  'questionsGroups/POST_QUESTION_SUCCESSFUL';
export const POST_QUESTION_FAILED = 'questionsGroups/POST_QUESTION_FAILED';

export const CHANGE_ANSWER_SUCCESSFUL =
  'questionsGroups/CHANGE_ANSWER_SUCCESSFUL';
export const CHANGE_ANSWER_FAILED = 'questionsGroups/CHANGE_ANSWER_FAILED';

export const GET_SCORING_SUCCESSFUL = 'questionsGroups/GET_SCORING_SUCCESSFUL';
export const GET_SCORING_FAILED = 'questionsGroups/GET_SCORING_FAILED';

export const RESET_QUESTIONS_SUCCESSFUL =
  'questionsGroups/RESET_QUESTIONS_SUCCESSFUL';
export const RESET_QUESTIONS_FAILED = 'questionsGroups/RESET_QUESTIONS_FAILED';

export const RESET_REVIEWING_SUCCESSFUL =
  'questionsGroups/RESET_REVIEWING_SUCCESSFUL';
export const RESET_REVIEWING_FAILED = 'questionsGroups/RESET_REVIEWING_FAILED';

export const RESET_HOMEWORK_SUCCESSFUL =
  'questionsGroups/RESET_HOMEWORK_SUCCESSFUL';
export const RESET_HOMEWORK_FAILED = 'questionsGroups/RESET_HOMEWORK_FAILED';

export const GET_HOMEWORK_PREVNEXT_QUESTION_SUCCESSFUL =
  'questionsGroups/GET_HOMEWORK_PREVNEXT_QUESTION_SUCCESSFUL';
export const GET_HOMEWORK_PREVNEXT_QUESTION_FAILED =
  'questionsGroups/GET_HOMEWORK_PREVNEXT_QUESTION_FAILED';

export const RESET_QUESTION_SCORING_REQUESTED =
  'questionsGroups/RESET_QUESTION_SCORING_REQUESTED';

export const GET_CHALLENGE_QUESTION_SUCCESSFUL =
  'questionsGroup/GET_CHALLENGE_QUESTION_SUCCESSFUL';
export const GET_CHALLENGE_QUESTION_FAILED =
  'questionsGroup/GET_CHALLENGE_QUESTION_FAILED';

export const POST_CHALLENGE_QUESTION_SUCCESSFUL =
  'questionsGroup/POST_CHALLENGE_QUESTION_SUCCESSFUL';
export const POST_CHALLENGE_QUESTION_FAILED =
  'questionsGroup/POST_CHALLENGE_QUESTION_FAILED';

export const GET_CHALLENGE_SCORING_SUCCESSFUL =
  'questionsGroup/GET_CHALLENGE_SCORING_SUCCESSFUL';
export const GET_CHALLENGE_SCORING_FAILED =
  'questionsGroup/GET_CHALLENGE_SCORING_FAILED';

export const RESET_CHALLENGE_SUCCESSFUL =
  'questionsGroup/RESET_CHALLENGE_SUCCESSFUL';
export const RESET_CHALLENGE_FAILED = 'questionsGroup/RESET_CHALLENGE_FAILED';

export const GET_HOMEWORK_CORRECTION_SUCCESSFUL =
  'questionsGroup/GET_HOMEWORK_CORRECTION_SUCCESSFUL';
export const GET_HOMEWORK_CORRECTION_FAILED =
  'questionsGroup/GET_HOMEWORK_CORRECTION_FAILED';

export const GET_HOMEWORK_PREVNEXT_CORRECTION_SUCCESSFUL =
  'questionsGroup/GET_HOMEWORK_PREVNEXT_CORRECTION_SUCCESSFUL';
export const GET_HOMEWORK_PREVNEXT_CORRECTION_FAILED =
  'questionsGroup/GET_HOMEWORK_PREVNEXT_CORRECTION_FAILED';

export const GET_HOMEWORK_PDF_SUCCESSFUL =
  'questionsGroups/GET_HOMEWORK_PDF_SUCCESSFUL';
export const GET_HOMEWORK_PDF_FAILED =
  'questionsGroups/GET_HOMEWORK_PDF_FAILED';
export const GET_HOMEWORK_PDF_REQUESTED =
  'questionsGroups/GET_HOMEWORK_PDF_REQUESTED';

const initialState = {
  question: {},
  answer: {},
  presentation: {},
  scoring: undefined,
  winner: undefined,
  challenger: null,
  isLoading: false,
  fileId: undefined,
  homework: {},
  pdf: null,
};

export default function QuestionsGroups(state = initialState, action) {
  switch (action.type) {
    case GET_QUESTION_REQUESTED:
      return {
        ...state,
        question: state.question.subChapterName
          ? {
            subChapterName: state.question.subChapterName,
            scoring: state.question.scoring,
          }
          : {},
        scoring: undefined,
        isLoading: true,
      };
    case GET_HOMEWORK_PDF_SUCCESSFUL:
      return {
        ...state,
        pdf: action.pdf,
      };
    case GET_HOMEWORK_FREE_QUESTION_SUCCESSFUL:
      return {
        ...state,
        presentation: action.presentation,
        question: action.question,
      };
    case GET_HOMEWORK_WITHOUT_QUESTION_SUCCESSFUL:
      return {
        ...state,
        presentation: action.presentation,
        question: action.question,
      };
    case GET_QUESTION_SUCCESSFUL:
      return {
        ...state,
        question: action.question,
        answer: initialState.answer,
        weight: action.weight,
        isLoading: false,
      };
    case GET_QUESTION_FAILED:
      return initialState;

    case GET_HOMEWORK_CORRECTION_REQUESTED:
      return {
        ...state,
        question: state.question.subChapterName
          ? {
            subChapterName: state.question.subChapterName,
            scoring: state.question.scoring,
          }
          : {},
        scoring: undefined,
        isLoading: true,
      };
    case GET_HOMEWORK_CORRECTION_SUCCESSFUL:
      return {
        ...state,
        question: action.question,
        answer: action.answer,
        weight: action.weight.weight,
        isLoading: false,
      };
    case GET_HOMEWORK_CORRECTION_FAILED:
      return initialState;

    case GET_HOMEWORK_PREVNEXT_CORRECTION_REQUESTED:
      return {
        ...state,
        question: state.question.subChapterName
          ? {
            subChapterName: state.question.subChapterName,
            scoring: state.question.scoring,
          }
          : {},
        scoring: undefined,
        isLoading: true,
      };
    case GET_HOMEWORK_PREVNEXT_CORRECTION_SUCCESSFUL:
      return {
        ...state,
        question: action.question,
        answer: action.answer,
        isLoading: false,
      };
    case GET_HOMEWORK_PREVNEXT_CORRECTION_FAILED:
      return initialState;

    case GET_CHALLENGE_QUESTION_REQUESTED:
      return {
        ...state,
        question: state.question.subChapterName
          ? {
            subChapterName: state.question.subChapterName,
            scoring: state.question.scoring,
          }
          : {},
        scoring: undefined,
        isLoading: true,
      };
    case GET_CHALLENGE_QUESTION_SUCCESSFUL:
      return {
        ...state,
        question: action.question,
        answer: initialState.answer,
        isLoading: false,
      };
    case GET_CHALLENGE_QUESTION_FAILED:
      return initialState;

    case GET_HOMEWORK_PREVNEXT_QUESTION_REQUESTED:
      return {
        ...state,
        question: state.question.subChapterName
          ? {
            subChapterName: state.question.subChapterName,
            scoring: state.question.scoring,
          }
          : {},
        scoring: undefined,
        isLoading: true,
      };
    case GET_HOMEWORK_PREVNEXT_QUESTION_SUCCESSFUL:
      return {
        ...state,
        question: action.question,
        answer: initialState.answer,
        isLoading: false,
        weight: action.weight,
      };
    case GET_HOMEWORK_PREVNEXT_QUESTION_FAILED:
      return initialState;

    case POST_QUESTION_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };
    case POST_QUESTION_SUCCESSFUL:
      return {
        ...state,
        answer: action.answer,
        isLoading: false,
      };
    case POST_QUESTION_FAILED:
      return initialState;

    case CHANGE_ANSWER_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };
    case CHANGE_ANSWER_SUCCESSFUL:
      return {
        ...state,
        answer: action.answer,
        isLoading: false,
      };
    case CHANGE_ANSWER_FAILED:
      return initialState;

    case POST_CHALLENGE_QUESTION_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };
    case POST_CHALLENGE_QUESTION_SUCCESSFUL:
      return {
        ...state,
        answer: action.answer,
        isLoading: false,
      };
    case POST_CHALLENGE_QUESTION_FAILED:
      return initialState;
    case GET_SCORING_SUCCESSFUL:
      return {
        ...state,
        scoring: action.scoring,
        waitingForSend: false,
      };
    case GET_SCORING_REQUESTED:
      return {
        ...state,
        waitingForSend: true,
      };
    case GET_SCORING_FAILED:
      return {
        ...state,
        scoring: undefined,
      };
    case SEND_FILE_REQUESTED:
      return {
        ...state,
        // fileId: action.fileName
      };
    case SEND_FILE_SUCCESS:
      return {
        ...state,
        fileId: action.fileId,
      };
    case SEND_FILE_FAILED:
      return {
        ...state,
      };
    case GET_CHALLENGE_SCORING_SUCCESSFUL:
      return {
        ...state,
        scoring: action.scoring,
        winner: action.winner,
        challenger: action.challenger,
      };
    case GET_CHALLENGE_SCORING_FAILED:
      return {
        ...state,
        scoring: undefined,
      };

    case RESET_QUESTIONS_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };
    case RESET_QUESTIONS_SUCCESSFUL:
      return initialState;
    case RESET_QUESTIONS_FAILED:
      return initialState;
    case RESET_QUESTION_SCORING_REQUESTED:
      return {
        ...state,
        question: {
          ...state.question,
          scoring: undefined,
        },
      };

    case RESET_REVIEWING_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };
    case RESET_REVIEWING_SUCCESSFUL:
      return initialState;
    case RESET_REVIEWING_FAILED:
      return initialState;

    case RESET_CHALLENGE_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };
    case RESET_CHALLENGE_SUCCESSFUL:
      return initialState;
    case RESET_CHALLENGE_FAILED:
      return initialState;

    case RESET_HOMEWORK_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };
    case RESET_HOMEWORK_SUCCESSFUL:
      return initialState;
    case RESET_HOMEWORK_FAILED:
      return initialState;
    default:
      return state;
  }
}

export const getQuestion =
  ({ courseId, subChapterId, homeworkId, fromV2, autoCorrectQuestion, contentIdFromProps, contentIdReviewing } ) =>
    (dispatch) => {
      dispatch({ type: RESET_ERROR });
      dispatch({ type: GET_QUESTION_REQUESTED });
      let endpoint = (subChapterId && !contentIdReviewing) ?
        fromV2 ? `/contents/sub_chapter/${subChapterId}/question_groups` : `/subchapters/${subChapterId}/questions_groups`
        : homeworkId
          ? `${fromV2 ? '/contents/homework' : '/homeworks'}/${homeworkId}/questions_groups`
          : contentIdReviewing ? `/content_blocs/${contentIdReviewing}/reviewing` :
            `/courses/${courseId}/reviewing`;

      return Api(autoCorrectQuestion ? `contents/question_groups/${contentIdFromProps}` : endpoint, {params: params})
        .then((res) => {
          let { data } = res;
          let { weight } = data;

          dispatch({
            type: GET_QUESTION_SUCCESSFUL,
            question: Array.isArray(data) ? {} : data,
            weight: Array.isArray(data) ? {} : weight,
          });

          return data;
        })
        .catch((err) => {
          dispatch({ type: GET_QUESTION_FAILED });
          if (err?.response?.status !== 401) {
            dispatch({
              type: SET_ERROR,
              value: err,
            });
          }
        });
    };

export const sendHomeworkOrQuestionFile =
  (
    homeworkId,
    file,
    questionId,
    targetFiles,
    setProgress,
    resetCallback,
    setFile,
    successCallback,
    contentId
  ) =>
    async (dispatch) => {
      dispatch({ type: SEND_FILE_REQUESTED });

      let formData = new FormData();
      let data = {};

      formData.append('document', file);

      let endpoint = `${contentId ? 'contents' : ''}/${contentId ? 'homework' : 'homeworks'}/${homeworkId}/files${
        questionId  ? '/' + questionId : ''
      }`;

      return Api.post(`${endpoint}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: params,
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : progressEvent.target.getResponseHeader('content-length') ||
            progressEvent.target.getResponseHeader(
              'x-decompressed-content-length'
            );
          if (totalLength !== null) {
            setProgress(Math.round((progressEvent.loaded * 100) / totalLength));
          }
        },
      })
        .then((res) => {
          data = res.data;
          dispatch({
            type: SEND_FILE_SUCCESS,
            fileId: data,
            fileName: targetFiles,
          });
          dispatch({ type: ISNT_LOADING });
          formData.delete('document', file);
          if (data.id || data.link)
            setFile((oldArray) => [
              ...oldArray,
              { id: data.id, link: data.link, name: data.name },
            ]);
          // else
          //   setFile(oldArray => [...oldArray, {id: 1, link: 'https://google.fr', name: 'Capture vidéo 2020-03-24 16:20:15'}]);
          successCallback();
        })
        .catch((err) => {
          if (err) {
            formData.delete('document', file);
            dispatch({
              type: SEND_FILE_FAILED,
              value: err,
            });
          }
          resetCallback();
          dispatch({ type: ISNT_LOADING });
        });
    };

export const removeHomeworkOrQuestionFile =
  (homeworkId, questionId, id_file, successCallbackRemove, contentId) =>
    async (dispatch) => {
      let endpoint = `${contentId ? 'contents' : '' }/${contentId ? 'homework' : 'homeworks'}/${homeworkId}/files/${
        questionId ? questionId + '/' : ''
      }${id_file}`;

      return Api.delete(`${endpoint}`, { params: params })
        .then(() => {
          dispatch({
            type: 'REMOVE_FILE_SUCCESS',
          });
          successCallbackRemove();
          dispatch({ type: ISNT_LOADING });
        })
        .catch((err) => {
          if (err) {
            dispatch({
              type: 'REMOVE_FILE_FAILED',
              value: err,
            });
          }
          dispatch({ type: ISNT_LOADING });
        });
    };

export const getPdfPrevisualisation = (homeworkId, contentTag) => async (dispatch) => {

  dispatch({ type: RESET_ERROR });
  dispatch({ type: GET_HOMEWORK_PDF_REQUESTED });
  let contentId = homeworkId ;
  let endpoint = contentTag === 2  ? `contents/homework/${contentId}/preview` : `/homeworks/${homeworkId}/preview`;

  return Api(endpoint, { params: params, responseType: 'arraybuffer' })
    .then((res) => {
      let { data } = res;

      dispatch({
        type: GET_HOMEWORK_PDF_SUCCESSFUL,
        pdf: data,
      });

      return data;
    })
    .catch((err) => {
      dispatch({ type: GET_HOMEWORK_PDF_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
    });
};

export const getHomeworkDocuments =
  ({ courseId, subChapterId, homeworkId }) =>
    async (dispatch) => {
      dispatch({ type: RESET_ERROR });
      dispatch({ type: GET_QUESTION_REQUESTED });

      let endpoint = subChapterId
        ? `/subchapters/${subChapterId}/questions_groups`
        : homeworkId
          ? `/homeworks/${homeworkId}/questions_groups`
          : `/courses/${courseId}/reviewing`;

      return Api(endpoint, { params: params })
        .then((res) => {
          let { data } = res;

          dispatch({
            type: GET_QUESTION_SUCCESSFUL,
            question: Array.isArray(data) ? {} : data,
          });

          return data;
        })
        .catch((err) => {
          dispatch({ type: GET_QUESTION_FAILED });
          if (err?.response?.status !== 401) {
            dispatch({
              type: SET_ERROR,
              value: err,
            });
          }
        });
    };

export const setQuestion =
  ({ question }) =>
    (dispatch) => {
      dispatch({
        type: GET_QUESTION_SUCCESSFUL,
        question,
      });
    };

export const StoreAnswerFreeQuestionHomework =
  (homeworkId, answerName, callbackNotice, fromV2) => async (dispatch) => {
    dispatch({ type: RESET_ERROR });
    dispatch({ type: POST_QUESTION_REQUESTED });

    let endpoint = homeworkId && fromV2 ? `contents/homework/${homeworkId}/questions_groups` : `/homeworks/${homeworkId}/questions_groups`;

    let answer = { answerName };
    let data = {};
    data.questionId = null;
    data.answerName = answer.answerName;
    let dataArray = [data];

    return Api.post(endpoint, dataArray, { params: params })
      .then((res) => {
        let { data } = res;
        callbackNotice();
        dispatch({
          type: POST_QUESTION_SUCCESSFUL,
          answer: Array.isArray(data) ? {} : data,
        });
      })
      .catch((err) => {
        dispatch({ type: POST_QUESTION_FAILED });
      });
  };

export const answerQuestion =
  ({ courseId, subChapterId, homeworkId, data, contentIdFromProps, autoCorrectQuestion }) =>
    (dispatch) => {
      dispatch({ type: RESET_ERROR });
      dispatch({ type: POST_QUESTION_REQUESTED });

      let contentId = homeworkId;
      let endpoint = subChapterId
        ? `/subchapters/${subChapterId}/questions_groups`
        : ( isNaN(courseId) && isNaN(subChapterId)) ?
          `contents/homework/${contentId}/questions_groups`
          : homeworkId
            ? `/homeworks/${homeworkId}/questions_groups`
            : `/courses/${courseId}/reviewing`;

      return Api.post((contentIdFromProps &&  !autoCorrectQuestion) ?
        `/contents/sub_chapter/${contentIdFromProps}` : (contentIdFromProps &&  autoCorrectQuestion) ?
          `/contents/question_groups/${contentIdFromProps}`
          : endpoint, data, { params: params })
        .then((res) => {
          dispatch({
            type: POST_QUESTION_SUCCESSFUL,
            answer: Object.keys(data[0]).some(x => x === 'answerIds') ? (res.data.feedback || res.data.correctAnswers?.length > 0 )
              ? res.data : {} : data[0].answerName,
          });
          const scoring =  store.getState()?.questionsGroups?.question?.scoring;
          const isLastQuestion = scoring?.dividend === scoring?.divisor;
          return {loadQuestionAfterSubmit: !( res?.data?.feedback || res?.data?.correctAnswers?.length > 0 || isLastQuestion)};
        })
        .catch((err) => {
          dispatch({ type: POST_QUESTION_FAILED });
          if (err?.response?.status !== 401) {
            dispatch({
              type: SET_ERROR,
              value: err,
            });
          }
        });
    };

export const answerReviewingContentQuestion =
  ({ contentId, data }) =>
    (dispatch) => {
      dispatch({ type: RESET_ERROR });
      dispatch({ type: POST_QUESTION_REQUESTED });
      return Api.post(`content_blocs/${contentId}/reviewing`, data, {params: params})
        .then((res) => {
          let { data } = res;
          dispatch({
            type: POST_QUESTION_SUCCESSFUL,
            answer: Array.isArray(data) ? {} : data,
          });
        })
        .catch((err) => {
          dispatch({type: POST_QUESTION_FAILED});
          if (err?.response?.status !== 401) {
            dispatch({
              type: SET_ERROR,
              value: err,
            });
          }
        });
    };

export const answerPreviewedQuestion =
  ({ data, previewedQuestion }) =>
    (dispatch) => {
      dispatch({ type: RESET_ERROR });
      dispatch({
        type: POST_QUESTION_SUCCESSFUL,
        answer: previewedQuestion.answer,
      });
    };

export const changeHomeworkAnswer =
  (homeworkId, data, questionId, contentTag) => async (dispatch) => {
    dispatch({ type: RESET_ERROR });
    dispatch({ type: CHANGE_ANSWER_REQUESTED });
    return Api.post(`${contentTag ? '/contents/homework' : '/homeworks'}/${homeworkId}/questions_groups`, data, {
      params: params,
    })
      .then((res) => {
        let { data } = res;

        dispatch({
          type: CHANGE_ANSWER_SUCCESSFUL,
          answer: Array.isArray(data) ? {} : data,
        });
      })
      .catch((err) => {
        dispatch({ type: CHANGE_ANSWER_FAILED });
        if (err?.response?.status !== 401) {
          dispatch({
            type: SET_ERROR,
            value: err,
          });
        }
      });
  };

export const getScoringHomeworkFreeQuestion =
  (homeworkId, callbackModal, contentTag) => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    dispatch({ type: RESET_ERROR });
    dispatch({ type: GET_SCORING_REQUESTED });

    let endpoint =
      homeworkId && contentTag === 2 ? `contents/homework/${homeworkId}/questions_groups/end` : `/homeworks/${homeworkId}/questions_groups/end`;

    return Api(endpoint, { params: params })
      .then((res) => {
        let {
          data: { scoring },
        } = res;

        dispatch({
          type: GET_SCORING_SUCCESSFUL,
          scoring: scoring,
        });
        dispatch({ type: ISNT_LOADING });
        callbackModal();
        return res;
      })
      .catch((err) => {
        dispatch({ type: GET_SCORING_FAILED });
        if (err?.response?.status !== 401) {
          dispatch({
            type: SET_ERROR,
            value: err,
          });
        }
        dispatch({ type: ISNT_LOADING });
      });
  };

export const getScoring =
  ({ courseId, subChapterId, homeworkId, isLoading, contentIdFromProps, contentIdReviewing }) =>
    async (dispatch) => {
      isLoading && dispatch({ type: IS_LOADING });
      dispatch({ type: RESET_ERROR });
      dispatch({ type: GET_SCORING_REQUESTED });

      let contentId = homeworkId;
      let endpoint =  ( isNaN(courseId) && isNaN(subChapterId)) && !contentIdReviewing ?
        `contents/homework/${contentId}/questions_groups/end`
        : subChapterId
          ? `/subchapters/${subChapterId}/end`
          : homeworkId
            ? `/homeworks/${homeworkId}/questions_groups/end`
            : `${contentIdReviewing ? 'content_blocs' : 'courses'}/${contentIdReviewing ? contentIdReviewing :courseId}/reviewing/end`;

      return Api(contentIdFromProps ? `/contents/sub_chapter/${contentIdFromProps}/end` :
        endpoint, { params: params })
        .then((res) => {
          let {
            data: { scoring },
          } = res;

          dispatch({
            type: GET_SCORING_SUCCESSFUL,
            scoring: scoring,
          });
          isLoading && dispatch({ type: ISNT_LOADING });

          return res;
        })
        .catch((err) => {
          dispatch({ type: GET_SCORING_FAILED });
          if (err?.response?.status !== 401) {
            dispatch({
              type: SET_ERROR,
              value: err,
            });
          }
          isLoading && dispatch({ type: ISNT_LOADING });
        });
    };

export const getHomeworkFreeQuestion = (homeworkId) => async (dispatch) => {
  dispatch({ type: IS_LOADING });
  dispatch({ type: RESET_ERROR });
  dispatch({ type: GET_SCORING_REQUESTED });

  let endpoint = homeworkId && `/homeworks/${homeworkId}`;

  return Api(endpoint, { params: params })
    .then((res) => {
      let data = res.data;
      dispatch({
        type: GET_HOMEWORK_FREE_QUESTION_SUCCESSFUL,
        presentation: data,
        question: { questions: [{ id: null, files: [] }] },
      });
      dispatch({ type: ISNT_LOADING });
      return res;
    })
    .catch((err) => {
      dispatch({ type: GET_SCORING_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};

export const getHomeworkWithoutQuestion = (homeworkId) => async (dispatch) => {
  dispatch({ type: IS_LOADING });
  dispatch({ type: RESET_ERROR });
  dispatch({ type: GET_SCORING_REQUESTED });

  let endpoint = homeworkId && `contents/homework/${homeworkId}/one`;

  return Api(endpoint, { params: params })
    .then((res) => {
      let data = res.data;
      dispatch({
        type: GET_HOMEWORK_WITHOUT_QUESTION_SUCCESSFUL,
        presentation: data,
        question: { questions: [{ id: null, files: [] }] },
      });
      dispatch({ type: ISNT_LOADING });
      return res;
    })
    .catch((err) => {
      dispatch({ type: GET_SCORING_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};

export const resetQuestions = (subChapterId, contentTag) => (dispatch) => {
  dispatch({ type: IS_LOADING });
  dispatch({ type: RESET_ERROR });
  dispatch({ type: RESET_QUESTIONS_REQUESTED });

  let url = contentTag === 2 ? `contents/sub_chapter/${subChapterId}` :
    `/subchapters/${subChapterId}/questions_groups`;

  return Api.put(url, null, {
    params: params,
  })
    .then((res) => {
      dispatch({ type: RESET_QUESTIONS_SUCCESSFUL });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: RESET_QUESTIONS_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};

export const resetQuestionScoring = () => (dispatch) =>
  dispatch({ type: RESET_QUESTION_SCORING_REQUESTED });

export const resetReviewing = (courseId, fromV2) => (dispatch) => {
  dispatch({ type: IS_LOADING });
  dispatch({ type: RESET_ERROR });
  dispatch({ type: RESET_REVIEWING_REQUESTED });

  let url = fromV2 ? `content_blocs/${courseId}/reviewing` :
    `/courses/${courseId}/reviewing`;

  return Api.put(url, null, { params: params })
    .then((res) => {
      dispatch({ type: RESET_REVIEWING_SUCCESSFUL });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: RESET_REVIEWING_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};

export const resetHomework = (homeworkId, contentId ) => (dispatch) => {
  dispatch({ type: IS_LOADING });
  dispatch({ type: RESET_ERROR });
  dispatch({ type: RESET_HOMEWORK_REQUESTED });

  return Api.put(
    `${contentId ? 'contents/homework/' : '/homeworks/'}${contentId ? contentId : homeworkId}/questions_groups`,
    null, {
      params: params,
    })
    .then((res) => {
      dispatch({ type: RESET_HOMEWORK_SUCCESSFUL });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: RESET_HOMEWORK_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};

export const getHomeworkPrevNextQuestion =
  (homeworkId, position, questionId, contentTag) => async (dispatch) => {
    dispatch({ type: IS_LOADING });
    dispatch({ type: RESET_ERROR });
    dispatch({ type: GET_HOMEWORK_PREVNEXT_QUESTION_REQUESTED });

    return Api(
      `${contentTag === 2 ? '/contents/homework/' : '/homeworks/'}${homeworkId}/free_questions_groups/${position}/${questionId}`,
      { params }
    )
      .then((res) => {
        let {
          data,
          data: { weight },
        } = res;

        dispatch({
          type: GET_HOMEWORK_PREVNEXT_QUESTION_SUCCESSFUL,
          question: Array.isArray(data) ? {} : data,
          weight: !Array.isArray(data) ? weight : {},
        });

        dispatch({ type: ISNT_LOADING });
        return data;
      })
      .catch((err) => {
        dispatch({ type: GET_HOMEWORK_PREVNEXT_QUESTION_FAILED });
        dispatch({ type: GET_HOMEWORK_PREVNEXT_QUESTION_FAILED });
        if (err?.response?.status !== 401) {
          dispatch({
            type: SET_ERROR,
            value: err,
          });
        }
        dispatch({ type: ISNT_LOADING });
      });
  };

export const getChallengeQuestion = (challengeId) => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: GET_CHALLENGE_QUESTION_REQUESTED });

  return Api(`/challenge/${challengeId}`, { params: params })
    .then((res) => {
      let { data } = res;

      dispatch({
        type: GET_CHALLENGE_QUESTION_SUCCESSFUL,
        question: Array.isArray(data) ? {} : data,
      });

      return data;
    })
    .catch((err) => {
      dispatch({ type: GET_CHALLENGE_QUESTION_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
    });
};

export const answerChallengeQuestion =
  ({ challengeId, data }) =>
    (dispatch) => {
      dispatch({ type: RESET_ERROR });
      dispatch({ type: POST_CHALLENGE_QUESTION_REQUESTED });

      return Api.post(`/challenge/${challengeId}`, data, { params: params })
        .then((res) => {
          let { data } = res;

          dispatch({
            type: POST_CHALLENGE_QUESTION_SUCCESSFUL,
            answer: Array.isArray(data) ? {} : data,
          });
        })
        .catch((err) => {
          dispatch({ type: POST_CHALLENGE_QUESTION_FAILED });
          if (err?.response?.status !== 401) {
            dispatch({
              type: SET_ERROR,
              value: err,
            });
          }
        });
    };

export const resetChallenge = (challengeId) => (dispatch) => {
  dispatch({ type: IS_LOADING });
  dispatch({ type: RESET_ERROR });
  dispatch({ type: RESET_CHALLENGE_REQUESTED });

  return Api.put(`/challenge/${challengeId}`, null, { params: params })
    .then((res) => {
      dispatch({ type: RESET_CHALLENGE_SUCCESSFUL });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: RESET_CHALLENGE_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};

export const getChallengeScoring =
  ({ challengeId, timer, isLoading }) =>
    (dispatch) => {
      isLoading && dispatch({ type: IS_LOADING });
      dispatch({ type: RESET_ERROR });
      dispatch({ type: GET_CHALLENGE_SCORING_REQUESTED });

      return Api.post(
        `/challenge/${challengeId}/end`,
        { time: timer },
        { params: params }
      )
        .then((res) => {
          let {
            data: { scoring, winner, challenger },
          } = res;

          dispatch({
            type: GET_CHALLENGE_SCORING_SUCCESSFUL,
            scoring: scoring,
            winner: winner,
            challenger: challenger,
          });
          isLoading && dispatch({ type: ISNT_LOADING });

          return res;
        })
        .catch((err) => {
          dispatch({ type: GET_CHALLENGE_SCORING_FAILED });
          if (err?.response?.status !== 401) {
            dispatch({
              type: SET_ERROR,
              value: err,
            });
          }
          isLoading && dispatch({ type: ISNT_LOADING });
        });
    };

export const getHomeworkCorrection = (homeworkId, contentTag) => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: GET_HOMEWORK_CORRECTION_REQUESTED });

  let url = contentTag === 2 ? `contents/homework/${homeworkId}/correction` :
    `/homeworks/${homeworkId}/correction`;

  return Api(url, { params: params })
    .then((res) => {
      let {
        data,
        data: { feedback, correctAnswers, userAnswers, weight },
      } = res;

      dispatch({
        type: GET_HOMEWORK_CORRECTION_SUCCESSFUL,
        question: Array.isArray(data) ? {} : data,
        answer: Array.isArray(data)
          ? {}
          : { feedback, correctAnswers, userAnswers },
        weight: !Array.isArray(data) ? { weight } : {},
      });
      dispatch({ type: ISNT_LOADING });

      return data;
    })
    .catch((err) => {
      dispatch({ type: GET_HOMEWORK_CORRECTION_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};

export const getHomeworkPrevNextCorrection =
  (homeworkId, position, questionId, contentTag) => (dispatch) => {
    dispatch({ type: IS_LOADING });
    dispatch({ type: RESET_ERROR });
    dispatch({ type: GET_HOMEWORK_PREVNEXT_CORRECTION_REQUESTED });

    let url = contentTag === 2 ?
      `contents/homework/${homeworkId}/correction/${position}/${questionId}`:
      `/homeworks/${homeworkId}/correction/${position}/${questionId}`;

    return Api(url, { params })
      .then((res) => {
        let {
          data,
          data: { feedback, correctAnswers, userAnswers, weight },
        } = res;

        dispatch({
          type: GET_HOMEWORK_PREVNEXT_CORRECTION_SUCCESSFUL,
          question: Array.isArray(data) ? {} : data,
          answer: Array.isArray(data)
            ? {}
            : { feedback, correctAnswers, userAnswers },
          weight: !Array.isArray(data) ? { weight } : {},
        });

        dispatch({ type: ISNT_LOADING });
        return data;
      })
      .catch((err) => {
        dispatch({ type: GET_HOMEWORK_PREVNEXT_CORRECTION_FAILED });
        if (err?.response?.status !== 401) {
          dispatch({
            type: SET_ERROR,
            value: err,
          });
        }
        dispatch({ type: ISNT_LOADING });
      });
  };
