import queryString from 'query-string';

import Api, {
  addAutorization,
  removeAuthorization,
  params,
  addCoaching,
} from '../modules/Api.js';

import { addToGTM } from '@components/molecules/gtm';

import { IS_LOADING, ISNT_LOADING } from './isLoading';
import { SET_ERROR, RESET_ERROR } from './error';
import { LOGIN_SUCCESSFUL, LOGIN_FAILED } from './auth';

export const GET_ME_SUCCESSFUL = 'me/GET_ME_SUCCESSFUL';
export const GET_ME_FAILED = 'me/GET_ME_FAILED';

export const UPDATE_PARAMETER_REQUESTED = 'me/UPDATE_PARAMETER_REQUESTED';
export const UPDATE_PARAMETER_SUCCESSFUL = 'me/UPDATE_PARAMETER_SUCCESSFUL';
export const UPDATE_PARAMETER_FAILED = 'me/UPDATE_PARAMETER_FAILED';

export const UPDATE_NEWBADGE_RESQUESTED = 'me/UPDATE_NEWBADGE_RESQUESTED';
export const UPDATE_NEWBADGE_SUCCESSFUL = 'me/UPDATE_NEWBADGE_SUCCESSFUL';
export const UPDATE_NEWBADGE_FAILED = 'me/UPDATE_NEWBADGE_FAILED';
export const UPDATE_UNREAD_NOTIFICATIONS_SUCCESSFUL = 'me/UPDATE_UNREAD_NOTIFICATIONS_SUCCESSFUL';

export const UPDATE_HASNEWRESOURCES_RESQUESTED =
  'me/UPDATE_HASNEWRESOURCES_RESQUESTED';
export const UPDATE_HASNEWRESOURCES_SUCCESSFUL =
  'me/UPDATE_HASNEWRESOURCES_SUCCESSFUL';
export const UPDATE_HASNEWRESOURCES_FAILED = 'me/UPDATE_HASNEWRESOURCES_FAILED';

export const GET_LOGIN_BACKGROUND_IMAGE_REQUESTED = 'loginBackgroundImage/GET_LOGIN_BACKGROUND_IMAGE_REQUESTED';
export const GET_LOGIN_BACKGROUND_IMAGE_SUCCESSFUL = 'loginBackgroundImage/GET_LOGIN_BACKGROUND_IMAGE_SUCCESSFUL';
export const GET_LOGIN_BACKGROUND_IMAGE_FAILED = 'loginBackgroundImage/GET_LOGIN_BACKGROUND_IMAGE_FAILED';
export const SEND_TIMESHEET_SUCCESSFUL = 'Send_time/SEND_TIMESHEET_SUCCESSFUL';
export const SEND_TIMESHEET_FAILED = 'me/SEND_TIMESHEET_FAILED';
export const MAKE_PAIEMENT_FAILED = 'me/MAKE_PAIEMENT_FAILED';
export const MAKE_PAIEMENT_SUCCESSFUL = 'me/MAKE_PAIEMENT_SUCCESSFUL';
export const MAKE_PAIEMENT_URL_EMPTY = 'me/MAKE_PAIEMENT_URL_EMPTY';

const initialState = {};

export default function Me(state = initialState, action) {
  switch (action.type) {
    case GET_ME_SUCCESSFUL:
      return action.user;
    case GET_ME_FAILED:
    case SEND_TIMESHEET_FAILED:
      return {};
    case UPDATE_PARAMETER_SUCCESSFUL:
      return {
        ...state,
        parameter: {
          ...state.parameter,
          ...action.parameter,
        },
      };
    case UPDATE_UNREAD_NOTIFICATIONS_SUCCESSFUL:
      return {
        ...state,
        unreadNotifications : action.unreadNotifications
      };
    case UPDATE_PARAMETER_FAILED:
    case MAKE_PAIEMENT_FAILED:
      return state;
    case GET_LOGIN_BACKGROUND_IMAGE_SUCCESSFUL:
      return {
        ...state,
        loginBackgroundImage : action.loginBackgroundImage
      };
    case SEND_TIMESHEET_SUCCESSFUL:
      return {
        ...state,
        selectedOffer:{
          ...state.selectedOffer,
          timeSheet: {
            connected: !state.selectedOffer.timeSheet.connected,
            connectedDate: Date.now()
          }
        }
      };
    case MAKE_PAIEMENT_SUCCESSFUL :
      return {
        ...state,
        paymentUrl: action.url
      };
    case MAKE_PAIEMENT_URL_EMPTY :
      return {
        ...state,
        paymentUrl: null
      };
    default:
      return state;
  }
}

export const check = () => (dispatch) => {
  dispatch({ type: IS_LOADING });

  // let token = localStorage.getItem('token');
  let coach =
    queryString.parse(window?.location?.search)['_coaching'] ||
    localStorage.getItem('coach');

  coach && addCoaching(coach);

  let token = window?.location?.href.split('/')[4]
    ? window?.location?.href.split('/')[4]
    : localStorage.getItem('token');
  let oldToken = localStorage.getItem('token');
  if (token && !oldToken) {
    localStorage.setItem('token', token);
  }
  if (token) {
    window?.history.replaceState({}, document.title, '/ ');
  }

  if (token) {
    getMe(token)
      .then((user) => {
        dispatch({
          type: GET_ME_SUCCESSFUL,
          user: user,
        });

        dispatch({ type: LOGIN_SUCCESSFUL });
        dispatch({ type: RESET_ERROR });
        dispatch({ type: ISNT_LOADING });
      })
      .catch((err) => {
        dispatch({
          type: SET_ERROR,
          value:
            err?.response && err?.response?.status === 401
              ? 'notLoggedIn'
              : 'error',
        });
        localStorage.removeItem('coach');

        dispatch({ type: GET_ME_FAILED });
        dispatch({ type: LOGIN_FAILED });
        dispatch({ type: ISNT_LOADING });
      });
  } else {
    // dispatch({ type: GET_ME_FAILED });
    // dispatch({ type: MAINTENANCE_MODE });
    dispatch({ type: ISNT_LOADING });
  }
};

export const refreshMe = () => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });

  let token = localStorage.getItem('token');
  let coach =
    queryString.parse(window?.location?.search)['_coaching'] ||
    localStorage.getItem('coach');

  coach && addCoaching(coach);

  if (token) {
    getMe(token)
      .then((user) => {
        dispatch({
          type: GET_ME_SUCCESSFUL,
          user: user,
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_ERROR,
          value:
            err?.response && err?.response?.status === 401
              ? 'notLoggedIn'
              : 'error',
        });
        localStorage.removeItem('coach');

        dispatch({ type: GET_ME_FAILED });
        dispatch({ type: LOGIN_FAILED });
        dispatch({ type: ISNT_LOADING });
      });
  } else {
    dispatch({ type: ISNT_LOADING });
  }
};

export const getMe = (token) =>
  new Promise((resolve, reject) => {
    removeAuthorization();
    addAutorization(token);

    Api('/me', { params: params })
      .then((res) => {
        addToGTM({
          loginStatus: 'logged',
          visitorID: res.data?.username,
          user_type: res.data?.roles?.includes('ROLE_PROSPECT')
            ? 'prospect'
            : res.data?.roles?.includes('ROLE_USER')
              ? 'eleve'
              : 'prospect',
          domain: res.data?.selectedOffer?.domainName,
          offer: res.data?.selectedOffer?.offerName,
        });

        resolve(res.data);
      })
      .catch((err) => {
        if (err?.response && err?.response?.status === 401)
          localStorage.removeItem('token');
        removeAuthorization();

        reject(err);
      });
  });

export const updateParameter = (parameter) => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: UPDATE_PARAMETER_REQUESTED });

  Api.put('/user_parameters', parameter, { params: params })
    .then((res) => {
      dispatch({
        type: UPDATE_PARAMETER_SUCCESSFUL,
        parameter: parameter,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: UPDATE_PARAMETER_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};

export const resetNewBadge = (parameter) => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: UPDATE_NEWBADGE_RESQUESTED });

  Api.put('/user_domain_offer/newbadge/state', parameter, { params: params })
    .then((res) => {
      dispatch({
        type: UPDATE_NEWBADGE_SUCCESSFUL,
        parameter: parameter,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: UPDATE_NEWBADGE_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};

export const resetHasResources = (parameter) => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: UPDATE_HASNEWRESOURCES_RESQUESTED });

  Api.put('/user_domain_offer/hasnewresources/state', parameter, {
    params: params,
  })
    .then((res) => {
      dispatch({
        type: UPDATE_HASNEWRESOURCES_SUCCESSFUL,
        parameter: parameter,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: UPDATE_HASNEWRESOURCES_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};

export const updateUnreadNotifications = (unreadNotifications) => (dispatch) => { 
  dispatch({ 
    type: UPDATE_UNREAD_NOTIFICATIONS_SUCCESSFUL,
    unreadNotifications: unreadNotifications,
  });
};

export const sendTimesheet = () => async (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });

  return Api.post('/user_domain_offer/timesheet', null, {
    params: params,
  })
    .then((data) => {
      if (data.status === 200) {
        dispatch({ type: SEND_TIMESHEET_SUCCESSFUL });
      } else {
        dispatch({ type: SEND_TIMESHEET_FAILED });
      }
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERROR,
        value: err,
      });
      dispatch({ type: SEND_TIMESHEET_FAILED });
      dispatch({ type: ISNT_LOADING });
    });
};

export const makePaiement = (data) => async (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });

  return Api.post('/payment/generate-link', data, {
    params: params,
  })
    .then((res) => {
      if (res.status === 200 && res.data?.url) {
        dispatch({
          type: MAKE_PAIEMENT_SUCCESSFUL,
          url: res.data.url
        });
      } else {
        dispatch({ type: MAKE_PAIEMENT_FAILED });
      }
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERROR,
        value: err,
      });
      dispatch({ type: MAKE_PAIEMENT_FAILED });
      dispatch({ type: ISNT_LOADING });
    });
};

export const setPayementUrlEmpty = ()=> async (dispatch)  => {
  dispatch({ type: MAKE_PAIEMENT_URL_EMPTY });
};
